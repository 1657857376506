import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';
import Html from 'cccisd-wysiwyg';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

import deploymentQuery from './deployment.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

ParentSurvey.propTypes = {
    /** The layout to use for the 404 page */
    errorLayout: PropTypes.func,
    /* From redux-router */
    match: PropTypes.object.isRequired,
    outcomesHash: PropTypes.string,
    respondentHash: PropTypes.string,
    miechv: PropTypes.bool,
};

function ParentSurvey({
    errorLayout = null,
    match: {
        params: { outcomesHash, respondentHash },
    },
    miechv,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [isClosed, setIsClosed] = useState(false);
    const [show404, setShow404] = useState(false);
    const [deploymentHandle, setDeploymentHandle] = useState();
    const [parent, setParent] = useState();
    const [caregiver, setCaregiver] = useState();

    useEffect(() => {
        evaluateDeployment();
    }, []);

    const logoutUser = async () => {
        if (Fortress.auth()) {
            await axios.get(Boilerplate.route('api.nexus.logout'));
            window.location.reload();
        }
    };

    let ErrorLayout = errorLayout;

    /**
     * Get the deployment handle and pawn data, which is needed to route
     * to the deployment for this pawn. It also retrieves pawn data
     * which is used to pre populate data in the flow
     */
    const evaluateDeployment = async () => {
        if (!respondentHash) {
            setShow404(true);
            setIsLoading(false);
            return;
        }
        try {
            const result = await apollo.query({
                query: deploymentQuery,
                fetchPolicy: 'network-only',
                variables: {
                    outcomesHash,
                    respondentHash,
                },
            });
            if (!result.data.flows.deployment.hash) {
                setShow404(true);
                setIsLoading(false);
                return;
            }
            if (!result.data.flows.deployment.isOpen) {
                setIsClosed(true);
            }

            await logoutUser();
            await setDeploymentHandle(result.data.flows.deployment.deploymentHandle);
            await setParent(result.data.roles.triplep_parent);
            await setCaregiver(result.data.roles.client);
        } catch (error) {
            setShow404(true);
        }
        await setIsLoading(false);
    };

    if (isLoading) {
        return <Loader loading />;
    }
    if (show404) {
        if (errorLayout) {
            return (
                <ErrorLayout>
                    <NotFound />
                </ErrorLayout>
            );
        }
        return <NotFound />;
    }
    if (isClosed) {
        return (
            <ErrorLayout>
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '2em',
                        width: '100%',
                    }}
                >
                    <Html content="This survey is no longer accepting responses." />
                </span>
            </ErrorLayout>
        );
    }

    return (
        <div style={{ marginTop: '2rem' }}>
            {miechv ? (
                <div>
                    <h3 className="text-center">{`${caregiver.fields.firstName} ${caregiver.fields.lastName}`}</h3>
                    <DeploymentPlayer
                        deploymentHandle={deploymentHandle}
                        pawnId={caregiver.pawn.pawnId}
                        pawnHash={caregiver.pawn.pawnHash}
                        disableLayout
                    />
                </div>
            ) : (
                <div>
                    <h3 className="text-center">{`${parent.fields.firstName} ${parent.fields.lastName}`}</h3>
                    <DeploymentPlayer
                        deploymentHandle={deploymentHandle}
                        pawnId={parent.pawn.pawnId}
                        pawnHash={parent.pawn.pawnHash}
                        disableLayout
                    />
                </div>
            )}
        </div>
    );
}

export default ParentSurvey;
